import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faTiktok, faXTwitter, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false); // State to toggle menu visibility

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        padding: "10px 0",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {/* Social Media Links */}
        <ul
          style={{
            display: "flex",
            gap: "30px", // Space between icons
            listStyle: "none",
            margin: 0,
            padding: 0,
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <li>
            <a
              title="Facebook"
              href="https://www.facebook.com/YourChristmasCountdownOfficial/"
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li>
            <a
              title="TikTok"
              href="https://www.tiktok.com/@yourxmascountdown.com"
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
            >
              <FontAwesomeIcon icon={faTiktok} />
            </a>
          </li>
          <li>
            <a
              title="Twitter"
              href="https://twitter.com/christmas_clock"
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
            >
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </li>
          <li>
            <a
              title="YouTube"
              href="https://www.youtube.com/c/yourchristmascountdown"
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
          <li>
            <a
              title="Instagram"
              href="https://www.instagram.com/yourchristmascountdown"
              target="_blank"
              rel="nofollow noopener noreferrer"
              style={{ color: "white", fontSize: "1.5rem" }}
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
        </ul>

        {/* Hamburger Menu */}
        <button
          onClick={toggleMenu}
          style={{
            background: "transparent",
            border: "none",
            color: "white",
            fontSize: "1.5rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          ☰{/* <span style={{ fontSize: "1rem" }}>Menu</span> */}
        </button>
      </div>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul
          style={{
            listStyle: "none",
            margin: 0,
            padding: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            textAlign: "center",
          }}
        >
          <li style={{ margin: "10px 0" }}>
            <a href="#home" style={{ color: "white", textDecoration: "none" }}>
              Home
            </a>
          </li>
          <li style={{ margin: "10px 0" }}>
            <a href="#features" style={{ color: "white", textDecoration: "none" }}>
              Features
            </a>
          </li>
          <li style={{ margin: "10px 0" }}>
            <a href="#about" style={{ color: "white", textDecoration: "none" }}>
              About
            </a>
          </li>
          <li style={{ margin: "10px 0" }}>
            <a href="#contact" style={{ color: "white", textDecoration: "none" }}>
              Contact
            </a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Menu;
