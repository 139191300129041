import React from "react";
import Countdown from "./components/Countdown.js";
import "./App.css";
import Snowfall from "react-snowfall";
import Footer from "./components/Footer/Footer.js";
import FooterButton from "./components/FooterButton/FooterButton.jsx";
import Menu from "./components/Menu/menu.js";
const App = () => {
  return (
    <div className="app">
      <Snowfall />
      {/* <Menu /> */}
      <header>
        <h1>🎄 Christmas Countdown 🎅</h1>
      </header>
      <main>
        <Countdown />
      </main>
      <Footer />
      <footer>
        <p>© 2024 ChristmasCountdowns</p>
      </footer>

      {/* <FooterButton /> */}
    </div>
  );
};

export default App;
